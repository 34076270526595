$(function () {
    setHeaderScroll();
    hamburger();
    fancyBoxInit();
    scrollRevealInit();
});

function scrolling() {
}

function resizing() {
}

$(document).on('scroll', scrolling);
$(window).on('resize', resizing);


