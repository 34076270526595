$(document).ready(function () {
// Create Countdown
var Countdown = {

    // Backbone-like structure
    $el: $('.countdown'),

    // Params
    countdown_interval: null,
    total_seconds     : 0,

    // Initialize the countdown
    init: function() {

        this.diff = 0

        // Setup dates
        this.startDate = new Date()

        // End date (fix)
        const dateTime = this.$el.attr('data-countdown-date');
        let dateTimeParts= dateTime.split(/[- :]/); // regular expression split that creates array with: year, month, day, hour, minutes, seconds values
        dateTimeParts[1]--; // monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one

        this.endDate = new Date(...dateTimeParts); // our Date object

        //this.endDate = Date.parse(this.$el.attr('data-countdown-date'))

        // Get total in seconds
        this.diff = Math.abs(this.endDate.getTime() - this.startDate.getTime()) / 1000

        this.delta = this.diff

        // Get days
        this.countDays = Math.floor(this.delta / 86400)
        this.delta -= this.countDays * 86400;

        // Get hours
        this.countHours = Math.floor(this.delta / 3600)
        this.delta -= this.countHours * 3600;

        // Get minutes
        this.countMinutes = Math.floor(this.delta / 60)
        this.delta -= this.countMinutes * 60;

        this.countSeconds = this.delta % 60

        // Initialize total seconds for function
        this.total_seconds = this.diff;


        // DOM
        this.$ = {
            days  : this.$el.find('.bloc-time.days .figure'),
            hours  : this.$el.find('.bloc-time.hours .figure'),
            minutes: this.$el.find('.bloc-time.min .figure'),
            seconds: this.$el.find('.bloc-time.sec .figure')
        };

        // Init countdown values
        this.values = {
            days  : this.countDays,
            hours  : this.countHours,
            minutes: this.countMinutes,
            seconds: this.countSeconds
        };


        // Animate countdown to the end
        this.count();
    },

    count: function() {

        var that    = this,
        $day_1 = this.$.days.eq(0),
        $day_2 = this.$.days.eq(1),
        $hour_1 = this.$.hours.eq(0),
        $hour_2 = this.$.hours.eq(1),
        $min_1  = this.$.minutes.eq(0),
        $min_2  = this.$.minutes.eq(1),
        $sec_1  = this.$.seconds.eq(0),
        $sec_2  = this.$.seconds.eq(1);

        this.countdown_interval = setInterval(function() {

            if(that.total_seconds > 0) {

                --that.values.seconds;

                if(that.values.minutes >= 0 && that.values.seconds < 0) {

                    that.values.seconds = 59;
                    --that.values.minutes;
                }

                if(that.values.hours >= 0 && that.values.minutes < 0) {

                    that.values.minutes = 59;
                    --that.values.hours;
                }

                if(that.values.days >= 0 && that.values.hours < 0) {

                    that.values.hours = 23;
                    --that.values.days;
                }

                // Update DOM values
                // Days
                that.checkHour(that.values.days, $day_1, $day_2);

                // Hours
                that.checkHour(that.values.hours, $hour_1, $hour_2);

                // Minutes
                that.checkHour(that.values.minutes, $min_1, $min_2);

                // Seconds
                that.checkHour(that.values.seconds, $sec_1, $sec_2);

                --that.total_seconds;
            }
            else {
                clearInterval(that.countdown_interval);
            }
        }, 1000);
    },

    animateFigure: function($el, value) {

        var that         = this,
        $top         = $el.find('.top'),
        $bottom      = $el.find('.bottom'),
        $back_top    = $el.find('.top-back'),
        $back_bottom = $el.find('.bottom-back');

        // Before we begin, change the back value
        $back_top.find('span').html(value);

        // Also change the back bottom value
        $back_bottom.find('span').html(value);

        // Then animate
        TweenMax.to($top, 0.8, {
            rotationX           : '-180deg',
            transformPerspective: 300,
            ease                : Quart.easeOut,
            onComplete          : function() {

                $top.html(value);

                $bottom.html(value);

                TweenMax.set($top, { rotationX: 0 });
            }
        });

        TweenMax.to($back_top, 0.8, {
            rotationX           : 0,
            transformPerspective: 300,
            ease                : Quart.easeOut,
            clearProps          : 'all'
        });
    },

    checkHour: function(value, $el_1, $el_2) {

        var val_1       = value.toString().charAt(0),
        val_2       = value.toString().charAt(1),
        fig_1_value = $el_1.find('.top').html(),
        fig_2_value = $el_2.find('.top').html();

        if(value >= 10) {

            // Animate only if the figure has changed
            if(fig_1_value !== val_1) this.animateFigure($el_1, val_1);
            if(fig_2_value !== val_2) this.animateFigure($el_2, val_2);
        }
        else {

            // If we are under 10, replace first figure with 0
            if(fig_1_value !== '0') this.animateFigure($el_1, 0);
            if(fig_2_value !== val_1) this.animateFigure($el_2, val_1);
        }
    }
};

// Let's go !

    Countdown.init();
});


